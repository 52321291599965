<template>
  <div class="about">
    <img
      class="banner"
      src="https://oss.pinmallzj.com/image/maintain/2023/08/31/Framex5752-glhdnny5ir5g.png"
      alt=""
    />
    <div class="videoBox">
      <div class="wrapper_1200">
        <div class="logo">
          <img src="@/assets/newImgs/logo1.png" alt="" class="logoImg" />
        </div>
        <div class="videoLvBox acea-row row-center">
          <video
            src="https://oss.pinmallzj.com/file/juru-saas/%E8%81%9A%E5%A6%82SaaS%E4%BB%8B%E7%BB%8D-v2.mp4"
            poster="https://oss.pinmallzj.com/image/maintain/2023/08/11/%E6%88%AA%E5%B1%8F2023-08-08x13x54x1-2bjvw1ncp0iv.png"
            width="910"
            height="510"
            controls
            loop
          ></video>
          <div class="info">
            浙江聚如信息技术有限公司,成立于2021年,我们是一家以供应链为核心并提供相关技术与服务的公司。我们聚焦各产业场景的核心需求，从技术构架、场景应用、数据管理、用户体验等层面构建数字化产业生态圈。我公司以供应链为核心整合了众多产业资源，助力多个产业场景实现数字化转型升级。与杭州金融投资集团、浙江省国际贸易集团、浙江物产集团、中国邮政储蓄银行股份有限公司浙江省分行、国家文物局直属单位中创文贸、县学研究院等大型国央企达成了一系列战略合作。
            <img src="@/assets/newImgs/Vector.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="contentBox acea-row row-middle" ref="content">
      <div
        class="center wrapper_1200 acea-row row-between-wrapper animated fadeInUp"
        v-show="contentShow"
      >
        <div
          class="centerItem pointer acea-row row-column row-left"
          v-for="(item, index) in infoList"
          :key="index"
          :style="{ 'background-image': 'url(' + item.pic + ')' }"
        >
          <div class="name">{{ item.name }}</div>
          <div class="zhanwei"></div>
          <div class="info">{{ item.info }}</div>
        </div>
      </div>
    </div>
    <div class="cooperateBox" ref="cooperate">
      <div class="title animated fadeInUp" v-show="cooperateShow">
        战略合作伙伴
      </div>
      <div
        class="entList wrapper_1200 acea-row row-between animated fadeInUp"
        v-show="cooperateShow"
      >
        <div
          class="entItem u-mb40 pointer"
          v-for="(item, index) in entList"
          :key="index"
        >
          <div class="name u-pl20">{{ item.name }}</div>
          <div class="subName u-pl20">{{ item.subName }}</div>
        </div>
      </div>
      <div class="settledBox acea-row row-middle row-between">
        <div>
          <div class="name">企业入驻申请</div>
          <div class="subName u-mt5">
            即刻免费搭建商城，开启您的企业数字化转型之路！
          </div>
        </div>
        <div class="btn pointer" @click="goToWhere('/entIn')">企业入驻申请</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      cooperateShow: false,
      contentShow: false,
      entList: [
        {
          name: "中国邮政储蓄银行股份有限公司浙江省分行",
          subName: "Postal Savings Bank of China",
        },
        {
          name: "杭州金融投资集团",
          subName: "Hangzhou Investment Holdings Co., Ltd.",
        },
        {
          name: "浙江省国际贸易集团",
          subName: "Zhejiang International Business Group Co., Ltd.",
        },
        {
          name: "浙江物产集团",
          subName: "Zhejiang Materials Industrial Group Co., Ltd.",
        },
        {
          name: "浙江农合集团有限责任公司",
          subName: "Zhejiang Nonghe Group Co., Ltd.",
        },
        {
          name: "中创文贸(北京)文物艺术品有限公司",
          subName:
            "Zhongchuang Culture and Trade (Beijing) Cultural Relics and Artworks Co., Ltd.",
        },
        {
          name: "县学研究院",
          subName:
            "Xian Xue (Beijing) e-commerce Technology Research Institute",
        },
      ],
      infoList: [
        {
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/31/%E4%BD%BF%E5%91%BD-fc6c4fk702ff.png",
          name: "公司使命",
          info: "赋能产业从而创造价值",
        },
        {
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/31/%E6%84%BF%E6%99%AF-sxfltbdneu3g.png",
          name: "公司愿景",
          info: "构建全产业数字化生态圈，让数据创造更大价值",
        },
        {
          pic: "https://oss.pinmallzj.com/image/maintain/2023/08/31/%E4%BB%B7%E5%80%BC%E8%A7%82-3r9ljnzcrus9.png",
          name: "公司价值观",
          info: "赋能 专业 真诚 专注",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    //页面路由切换
    goToWhere(page, index) {
      this.$router.push({
        path: page,
      });
    },
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
      // this.gdjz('earth', 'earth animated bounceInDown', 50)
      let _this = this;
      let refArray = [
        {
          ref: "content",
          show: "contentShow",
          delayPos: 200,
        },
        {
          ref: "cooperate",
          show: "cooperateShow",
          delayPos: 400,
        },
      ];
      refArray.forEach((r, i) => {
        _this.gdjz(r.ref, r.delayPos, () => {
          _this[r.show] = true;
        });
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        // console.log(a, "a", b, "b", c, "c", d, "d");
        if (b + c > a) {
          callback && callback();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  padding-top: 91px;
}
.banner {
  width: 100%;
  min-height: 500px;
}
.videoBox {
  padding: 124px 0 157px 0;
  box-shadow: 0px 10px 27px 0px #d4dbe5 inset,
    0px -11px 17px 0px rgba(255, 255, 255, 0.3) inset;
  .logo {
    img {
      width: 263px;
      height: 61px;
    }
  }
  .videoLvBox {
    margin-top: 87px;
    .info {
      margin-top: 64px;
      text-indent: 1em;
      color: #333;
      font-family: PingFang SC;
      font-size: 23px;
      font-style: normal;
      font-weight: 500;
      line-height: 152%; /* 34.96px */
      letter-spacing: 1.955px;
      position: relative;
      img {
        position: absolute;
        right: 0;
        bottom: 0;
        transform: translateY(100%);
      }
    }
  }
}
.cooperateBox {
  background: #fff;
  // height: 1040px;
  padding: 74px 100px;
  .title {
    color: #0069f8;
    text-align: center;
    font-family: PingFang SC;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .entList {
    margin-top: 104px;
    background-image: url("https://oss.pinmallzj.com/image/maintain/2023/08/10/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230810154220-80k0ez6xui6q.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .entItem {
      width: 520px;
      .name {
        color: #333a45;
        font-family: PingFang SC;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.259px;
        position: relative;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: inline-block;
          content: "";
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: linear-gradient(161deg, #a8afee 0%, #4e77e4 100%);
          filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
        }
      }
      .subName {
        color: #627597;
        font-family: PingFang SC;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.219px;
      }
    }
  }

  .settledBox {
    margin: auto;
    width: 76%;
    min-width: 1200px;
    height: 219px;
    border-radius: 2px;
    border: 2px solid #fff;
    background: linear-gradient(179deg, #eff3f7 0%, #feffff 100%);
    box-shadow: 1px 5px 21px 0px #e7eaf4;
    padding: 0 174px 0 131px;
    position: relative;
    z-index: 100;
    .name {
      color: #333;
      font-family: PingFang SC;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.3px;
    }
    .subName {
      color: #333;
      font-family: PingFang SC;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.211px;
    }
    .btn {
      user-select: none;
      width: 232px;
      height: 52px;
      color: #3d6bf6;
      font-family: PingFang SC;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.178px;
      border-radius: 2px;
      border: 1px solid #fff;
      background: linear-gradient(180deg, #fafcff 0%, #fff 100%);
      text-align: center;
      line-height: 52px;
      transition: all 0.5s;
      &:hover {
        box-shadow: 2px 2px 9px 0px #e4e9f7;
      }
    }
  }
}
.contentBox {
  min-height: 813px;
  background-image: url("https://oss.pinmallzj.com/image/maintain/2023/08/31/Rectanglex14210-j4kykfgpfx80.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .centerItem {
    width: 372px;
    height: 435px;
    padding: 280px 100px 35px 45px;
    background-image: url("https://oss.pinmallzj.com/image/maintain/2023/08/31/Rectanglex14210-j4kykfgpfx80.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .name {
      color: #fff;
      font-family: PingFang SC;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.4px;
    }
    .zhanwei {
      margin: 20px 0;
      background: #fff;
      width: 84px;
      height: 1px;
    }
    .info {
      color: #fff;
      font-family: PingFang SC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 165%;
      letter-spacing: 0.315px;
    }
  }
}
</style>
